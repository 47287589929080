<template>
    <li
        class="blog-categories-widget__category blog-categories-widget__category--all single-category-wrapper"
    >
        <div class="parent-category">
            <span
                v-if="showSubCategoryToggle"
                class="sub-category-toggle"
                tabindex="0"
                @click="showSubCategory = !showSubCategory"
                @keypress.enter="showSubCategory = !showSubCategory"
            >
                <i
                    :class="{ rotate: showSubCategory }"
                    class="fa-sharp fa-regular fa-angle-up"
                />
            </span>
            <component
                :is="linkComponent"
                :href="useAnchorTag ? `/blog/archive/${category.id}/${category.name}` : '#'"
                :to="useAnchorTag ? null : {
                    path: $path('cmnty_blog_overview_categories', {
                        id: category.id,
                        slug: category.name
                    })
                }"
                class="blog-categories-widget__category-link pointer"
            >
                <span class="blog-categories-widget__category-name">
                    {{ category.name }}
                </span>
                <span class="blog-categories-widget__category-article-count">
                    {{ category.article_count || category.articleCount }}
                </span>
            </component>
        </div>
        <div v-show="showSubCategory" class="sub-category-wrapper">
            <ul>
                <li v-for="children in category.children" :key="children.id" class="sub-category-wrapper__list" tabindex="0">
                    <component
                        :is="linkComponent"
                        :href="useAnchorTag ? `/blog/archive/${children.id}/${children.name}` : null"
                        :to="useAnchorTag ? null : {
                            path: $path('cmnty_blog_overview_categories', {
                                id: children.id,
                                slug: children.name
                            })
                        }"
                        class="blog-categories-widget__category-link pointer"
                    >
                        <span class="sub-category-wrapper__list--name">{{ children.name }}</span>
                        <span class="sub-category-wrapper__list--count">{{ children.article_count || children.articleCount }}</span>
                    </component>
                </li>
            </ul>
        </div>
    </li>
</template>

<script>
    export default {
        name: 'BlogSingleCategoriesWidget',
        props: {
            category: {
                type: Object,
                default: () => {
                },
            },
            useAnchorTag: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data() {
            return {
                showSubCategory: false,
            };
        },
        computed: {
            showSubCategoryToggle() {
                return Object.keys(this.category.children).length > 0;
            },
            linkComponent() {
                return this.useAnchorTag ? 'a' : 'router-link';
            },
        },
    };
</script>

<style lang="scss">
.sub-category-wrapper {
    ul {
        padding: 0px !important;
    }

    &__list {
        border-top: 1px solid #dadada;
        list-style: none;
        width: 100%;
        padding-left: 40px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        cursor: pointer;
    }
}

.parent-category {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sub-category-toggle {
    margin-right: 10px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.rotate {
    transform: rotate(180deg);
}
</style>

<template>
    <section :class="`blog-categories-widget--${position}`" class="widget blog-categories-widget">
        <div v-if="description" class="widget__description">
            {{ description }}
        </div>
        <smooth-reflow class="widget__content blog-categories-widget__body">
            <ul
                id="injectable"
                class="blog-categories-widget__categories"
            >
                <li
                    class="blog-categories-widget__category blog-categories-widget__category--all single-category-wrapper blog-categories-widget-injectable"
                >
                    <div class="parent-category">
                        <a
                            :href="articlepath"
                            class="blog-categories-widget__category-link pointer"
                        >
                            <span class="blog-categories-widget__category-name">
                                {{ allarticlelabel }}
                            </span>
                            <span class="blog-categories-widget__category-article-count">
                                {{ allArticlesCount }}
                            </span>
                        </a>
                    </div>
                </li>

                <single-category
                    v-for="category in getCategories"
                    :key="category.id"
                    class="blog-categories-widget-injectable"
                    :category="category"
                    :use-anchor-tag="true"
                />
            </ul>
        </smooth-reflow>
    </section>
</template>

<script>
    import SingleCategory from './SingleCategory.vue';

    export default {
        name: 'BlogCategoriesWidget2',
        components: {
            SingleCategory,
        },
        props: {
            title: {
                type: String,
                required: true,
            },
            allarticlelabel: {
                type: String,
                required: true,
            },
            description: {
                type: String,
                required: true,
            },
            position: {
                type: String,
                required: true,
            },
            categories: {
                type: Object,
                default: () => {
                },
            },
            articlepath: {
                type: String,
                required: true,
            },
            totalarticle: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                loaded: false,
            };
        },
        computed: {
            getCategories() {
                return JSON.parse(this.categories.toString());
            },
            allArticlesCount() {
                return this.totalarticle;
            },
        },
    };
</script>

<style lang="scss">
.blog-categories-widget {
    &__categories {
        @include reset-list();
    }

    &__category {
        //font-size: $font-xs;

        &:not(:first-child) {
            border-top: 1px solid $color-grey-light;
        }
    }

    &__category-link {
        color: $color-text-base;
        display: flex;
        justify-content: space-between;
        padding-bottom: $gutter-s;
        padding-top: $gutter-s;
        width: 100%;

        &:hover {
            color: var(--color-primary);
        }
    }

    &__category-article-count {
        flex-shrink: 0;
        margin-left: $gutter-m;
    }

    &__placeholder-name {
        width: 150px;
    }

    &__placeholder-count {
        display: inline-flex;
        justify-content: flex-end;
        width: 20px;
    }
}
</style>

<style lang="scss">
.blog-categories-widget {
    &__placeholder {
        .vue-content-placeholders-text__line {
            margin-bottom: 0;
        }
    }
}
#injectable {
    .blog-categories-widget-injectable {

        a {
            padding: 6px 0px !important;
        }
    }
    .blog-categories-widget-injectable :hover {
        background: none !important;
    }
}

body #sidebar  .widget-blog-categories ul#injectable  li:hover {
    background: none !important;
    a, i {
        color: #444;
    }
}
body #sidebar  .widget-blog-categories ul#injectable {
    margin: 0px -15px;
    li ul li {
        margin-left: 0px !important;
        a{
            border-left: none;
            padding: 6px 0px 6px 40px !important;
        }
    }
}

</style>
